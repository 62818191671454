import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Timeless Events
			</title>
			<meta name={"description"} content={"Удосконалення ваших моментів"} />
			<meta property={"og:title"} content={"Про нас | Timeless Events"} />
			<meta property={"og:description"} content={"Удосконалення ваших моментів"} />
			<meta property={"og:image"} content={"https://blumoran.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Наш шлях у Timeless Events розпочався з простого бачення: створювати виняткові події, які резонують ще довго після того, як останній гість піде. Завдяки глибокій пристрасті до святкування та прискіпливій увазі до деталей, ми перетворилися на провідну службу планування подій, відому своєю прихильністю до якості та елегантності. Нашою командою рухає бажання перетворювати звичайні події на вражаючі святкування, створюючи заходи, які не тільки візуально вражають, але й викликають емоційний резонанс.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://blumoran.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 46px/1.2 --fontFamily-sans">
					Наш досвід
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" color="#626970">
					Наріжним каменем Timeless Events є наша віддана команда. Кожен член команди привносить унікальний набір навичок та особистий підхід до кожного заходу, гарантуючи, що кожна подія буде шедевром, створеним на замовлення. Наші планувальники, дизайнери та експерти з логістики працюють в гармонії, щоб бездоганно поєднати традиції та інновації, створюючи ідеальний баланс, який є водночас свіжим та позачасовим.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 50px 0px"
						margin="0px 0px 40px 0px"
						padding="0px 0px 40px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
					>
						<LinkBox justify-content="center" align-items="flex-start">
							<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans">
							Чим вирізняються позачасові події
							</Text>
						</LinkBox>
						<Text margin="0px 0px 30px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" color="#626970">
						Увага до деталей: Кожен елемент, від освітлення до планування, ретельно спланований для створення цілісного середовища, що запам'ятовується.
							<br />
							Творче бачення: Наші заходи відомі своїм творчим чуттям та естетичною точністю, що робить кожен з них унікальним витвором мистецтва.
							<br />
							Неперевершений професіоналізм: Професіоналізм нашої команди забезпечує плавний, приємний процес планування та бездоганне проведення заходу.
							<br />
							Адаптивні стилі: Незалежно від того, чи ви уявляєте собі класичний, сучасний або еклектичний захід, ми адаптуємо кожну деталь, щоб вона відповідала вашій мрії.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
						<LinkBox margin="0px 0px 15px 0px" align-items="flex-start">
							<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans">
							Створіть свою ідеальну подію з нами
							</Text>
						</LinkBox>
						<Text margin="0px 0px 30px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" color="#626970">
						Уявіть собі подію, де кожна деталь відображає ваше бачення, де кожна мить продумана так, щоб перевершити ваші очікування. У Timeless Events ми втілюємо це бачення в реальність. Ми запрошуємо вас створити свою історію разом з нами, щоб втілити ваші мрії в життя так, як це можемо зробити тільки ми. Приєднуйтесь до нас, і нехай ваша наступна подія стане свідченням магії, яка відбувається, коли творчість зустрічається з відданістю справі.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://blumoran.com/img/5.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://blumoran.com/img/4.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					margin="20px 0px 0px 0px"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://blumoran.com/img/3.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});